import { gql } from "@apollo/client";

export const FETCH_LAST_SIGNED_PUBLIC_ADDRESS = gql`
  query($orderBy: String!, $orderDirection: String!) {
    confirmTransferEvents(orderBy: $orderBy, orderDirection: $orderDirection) {
      signature
      oldSignerAddress
      newSignerAddress
      epoch
      dynasty
      timestamp
      transactionHash
    }
  }
`;

export const FETCH_CURRENT_EPOCH = gql`
  query {
    epoches {
      epoch
    }
  }
`;

export const FETCH_CURRENT_DYNASTY = gql`
  query {
    dynasties {
      dynasty
    }
  }
`;

export const FETCH_CURRENT_MODE = gql`
  query {
    modes {
      mode
    }
  }
`;

export const FETCH_LAST_FINALIZED_BLOCK = gql`
  query($orderBy: String!, $orderDirection: String!) {
    finalizeBlockEvents(orderBy: $orderBy, orderDirection: $orderDirection) {
      blockWinner
      signature
      sender
      signerAddress
      epoch
      dynasty
      messageData
      transactionHash
    }
  }
`;

export const FETCH_FINALIZED_BLOCKS = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    finalizeBlockEvents(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
    ) {
      blockWinner
      signature
      sender
      signerAddress
      epoch
      dynasty
      messageData
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_REQUESTS = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    requests(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
    ) {
      id
      requestId
      numCommits
      indexedAt
      requestFinalizedEpoch
      requestFirstCommittedEpoch
      resultDestinationChainDomain
      resultRequestingChainDomain
      resultTarget
      resultValues
      status
      epochConsensusReached
    }
  }
`;

export const FETCH_REQUEST = gql`
  query($requestId: String!) {
    requests(where: { requestId: $requestId }) {
      id
      requestId
      numCommits
      indexedAt
      requestFinalizedEpoch
      requestFirstCommittedEpoch
      resultDestinationChainDomain
      resultRequestingChainDomain
      resultTarget
      resultValues
      status
      epochConsensusReached
    }
  }
`;

export const FETCH_COMMITTED_EVENTS_BY_REQUEST_ID = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
    $requestId: String!
  ) {
    requestCommittedEvents(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { requestId: $requestId }
    ) {
      id
      epoch
      requestId
      sender
      timestamp
      transactionHash
      validatorId
      valueHash
      dynasty
    }
  }
`;

export const FETCH_REVEALED_EVENTS_BY_REQUEST_ID = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
    $requestId: String!
  ) {
    requestRevealedEvents(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { requestId: $requestId }
    ) {
      id
      requestId
      epoch
      sender
      requestingChainDomain
      target
      timestamp
      transactionHash
      values
      destinationChainDomain
      dynasty
    }
  }
`;

export const FETCH_ATTESTED_SIGNERS = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    attestedEvents(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
    ) {
      epoch
      dynasty
      validatorId
      sender
      signerAddress
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_VALIDATORS = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    validators(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
    ) {
      stake
      validatorId
      lastAttestedEpoch
      lastAttestedDynasty
      lastFinalizedBlock
      lastEpochChurnedOut
      lastDynastyChurnedOut
      isChurnedOut
      address
      delegationAccepted
      dToken
    }
  }
`;

export const FETCH_VALIDATOR_BY_ADDRESS = gql`
  query($address: String!) {
    validators(where: { address: $address }) {
      stake
      validatorId
      commission
      delegationAccepted
      lastAttestedEpoch
      lastAttestedDynasty
      lastFinalizedBlock
      lastEpochChurnedOut
      lastDynastyChurnedOut
      isChurnedOut
      address
      delegationAccepted
      dToken
      claimableValidatorRewards
    }
  }
`;

export const FETCH_ALL_TRANSACTIONS = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    transactions(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
    ) {
      id
      timestamp
      transactionHash
      address
      dynasty
      epoch
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }
      slashedEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        sender
        validator
        validatorId
        transactionHash
      }
      stakeChangeEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        reason
        timestamp
        transactionHash
        validatorId
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      quitNetworkEvent {
        id
        validator
        validatorId
        toBeChurnedOutForUnstake
        epoch
        dynasty
        transactionHash
        timestamp
      }

      withdrawSignalledEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      withdrawInitiatedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        totalSupply
        transactionHash
        timestamp
      }

      withdrawExecutedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      resetSignalLockEvent {
        id
        validatorId
        validator
        delegator
        epoch
        dynasty
        transactionHash
        timestamp
      }

      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_TRANSACTION_BY_HASH = gql`
  query($hash: String!) {
    transactions(where: { transactionHash: $hash }) {
      id
      transactionHash
      epoch
      dynasty
      timestamp
      address
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }
      slashedEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        sender
        validator
        validatorId
        transactionHash
      }
      stakeChangeEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        reason
        timestamp
        transactionHash
        validatorId
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      quitNetworkEvent {
        id
        validator
        validatorId
        toBeChurnedOutForUnstake
        epoch
        dynasty
        transactionHash
        timestamp
      }

      withdrawSignalledEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      withdrawInitiatedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        totalSupply
        transactionHash
        timestamp
      }

      withdrawExecutedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      resetSignalLockEvent {
        id
        validatorId
        validator
        delegator
        epoch
        dynasty
        transactionHash
        timestamp
      }
      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_TRANSACTION_BY_ADDRESS = gql`
  query(
    $address: String!
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    transactions(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { address: $address }
    ) {
      id
      timestamp
      transactionHash
      address
      dynasty
      epoch
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }
      slashedEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        sender
        validator
        validatorId
        transactionHash
      }
      stakeChangeEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        reason
        timestamp
        transactionHash
        validatorId
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      quitNetworkEvent {
        id
        validator
        validatorId
        toBeChurnedOutForUnstake
        epoch
        dynasty
        transactionHash
        timestamp
      }

      withdrawSignalledEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      withdrawInitiatedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        totalSupply
        transactionHash
        timestamp
      }

      withdrawExecutedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      resetSignalLockEvent {
        id
        validatorId
        validator
        delegator
        epoch
        dynasty
        transactionHash
        timestamp
      }

      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_TRANSACTION_BY_ADDRESS_ACTION = gql`
  query(
    $address: String!
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
    $actions: [String]
  ) {
    transactions(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { address: $address, action_in: $actions }
    ) {
      id
      timestamp
      transactionHash
      address
      dynasty
      epoch
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }
      slashedEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        sender
        validator
        validatorId
        transactionHash
      }
      stakeChangeEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        reason
        timestamp
        transactionHash
        validatorId
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      quitNetworkEvent {
        id
        validator
        validatorId
        toBeChurnedOutForUnstake
        epoch
        dynasty
        transactionHash
        timestamp
      }

      withdrawSignalledEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      withdrawInitiatedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        totalSupply
        transactionHash
        timestamp
      }

      withdrawExecutedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      resetSignalLockEvent {
        id
        validatorId
        validator
        delegator
        epoch
        dynasty
        transactionHash
        timestamp
      }

      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_TRANSACTION_BY_ACTION = gql`
  query(
    $orderBy: String!
    $orderDirection: String!
    $actions: [String]
    $skip: Int!
    $first: Int!
  ) {
    transactions(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { action_in: $actions }
    ) {
      id
      timestamp
      transactionHash
      address
      dynasty
      epoch
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }
      slashedEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        sender
        validator
        validatorId
        transactionHash
      }
      stakeChangeEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        reason
        timestamp
        transactionHash
        validatorId
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      quitNetworkEvent {
        id
        validator
        validatorId
        toBeChurnedOutForUnstake
        epoch
        dynasty
        transactionHash
        timestamp
      }

      withdrawSignalledEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      withdrawInitiatedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        totalSupply
        transactionHash
        timestamp
      }

      withdrawExecutedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      resetSignalLockEvent {
        id
        validatorId
        validator
        delegator
        epoch
        dynasty
        transactionHash
        timestamp
      }

      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      # resetLock {
      #   id
      #   validator
      #   delegator
      #   dynasty
      #   epoch
      #   sender
      #   transactionHash
      # }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_ATTESTED_SIGNERS_BY_HASH = gql`
  query($hash: String!) {
    attestedEvents(where: { transactionHash: $hash }) {
      epoch
      dynasty
      validatorId
      sender
      signerAddress
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_ATTESTED_SIGNERS_BY_ADDRESS = gql`
  query(
    $address: String!
    $orderBy: String!
    $orderDirection: String!
    $skip: Int!
    $first: Int!
  ) {
    attestedEvents(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { sender: $address }
    ) {
      epoch
      dynasty
      validatorId
      sender
      signerAddress
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_FINALIZED_BLOCKS_BY_HASH = gql`
  query($hash: String!) {
    finalizeBlockEvents(where: { transactionHash: $hash }) {
      blockWinner
      signature
      sender
      signerAddress
      epoch
      dynasty
      messageData
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_FINALIZED_BLOCKS_BY_EPOCH = gql`
  query($epoch: Int!) {
    finalizeBlockEvents(where: { epoch: $epoch }) {
      blockWinner
      signature
      sender
      signerAddress
      epoch
      dynasty
      messageData
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_FINALIZED_BLOCKS_BY_ADDRESS = gql`
  query($address: String!, $orderBy: String!, $orderDirection: String!) {
    finalizeBlockEvents(
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { sender: $address }
    ) {
      blockWinner
      signature
      sender
      signerAddress
      epoch
      dynasty
      messageData
      transactionHash
      timestamp
    }
  }
`;

export const FETCH_SEARCH_BY_ADDRESS = gql`
  query($address: String!) {
    validators(where: { address: $address }) {
      address
    }
  }
`;

export const FETCH_TRANSACTION_BY_HASH_SEARCH = gql`
  query($hash: String!) {
    transactions(where: { transactionHash: $hash }) {
      timestamp
      transactionHash
      address
    }
  }
`;

export const FETCH_LAST_SYNC_BLOCK = gql`
  query {
    _meta {
      block {
        number
      }
    }
  }
`;

export const FETCH_TRANSACTIONS_BY_EPOCH = gql`
  query($epoch: String!) {
    transactions(where: { epoch: $epoch }) {
      id
      timestamp
      transactionHash
      address
      dynasty
      epoch
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }
      slashedEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        sender
        validator
        validatorId
        transactionHash
      }
      stakeChangeEvent {
        dynasty
        epoch
        id
        newStake
        prevStake
        reason
        timestamp
        transactionHash
        validatorId
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      quitNetworkEvent {
        id
        validator
        validatorId
        toBeChurnedOutForUnstake
        epoch
        dynasty
        transactionHash
        timestamp
      }

      withdrawSignalledEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      withdrawInitiatedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        totalSupply
        transactionHash
        timestamp
      }

      withdrawExecutedEvent {
        id
        withdrawer
        validator
        validatorId
        epoch
        dynasty
        amount
        newStake
        transactionHash
        timestamp
      }

      resetSignalLockEvent {
        id
        validatorId
        validator
        delegator
        epoch
        dynasty
        transactionHash
        timestamp
      }

      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      # resetLock {
      #   id
      #   validator
      #   delegator
      #   dynasty
      #   epoch
      #   sender
      #   transactionHash
      # }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_TRANSACTIONS_BY_DYNASTY = gql`
  query($dynasty: String!, $orderBy: String!, $orderDirection: String!) {
    transactions(
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { dynasty: $dynasty }
    ) {
      id
      timestamp
      transactionHash
      address
      dynasty
      epoch
      action
      attestedEvent {
        epoch
        dynasty
        validatorId
        sender
        signerAddress
        transactionHash
      }
      confirmTransferEvent {
        id
        signature
        oldSignerAddress
        newSignerAddress
        epoch
        dynasty
        timestamp
        transactionHash
      }
      requestCommittedEvent {
        id
        epoch
        dynasty
        requestId
        sender
        timestamp
        transactionHash
        validatorId
        valueHash
      }
      requestStatusChangedEvent {
        id
        requestId
        epoch
        dynasty
        status
        timestamp
        transactionHash
      }
      requestRevealedEvent {
        id
        epoch
        dynasty
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        timestamp
        transactionHash
        values
      }
      validatorSnitchedEvent {
        id
        requestId
        destinationChainDomain
        bountyHunter
        dynasty
        epoch
        requestingChainDomain
        target
        timestamp
        transactionHash
        validatorId
        values
      }
      createRequestEvent {
        dynasty
        requesterAddress
        numRequests
        timestamp
        epoch
        transactionHash
        sourceChainId
        sourceAddress
        payload
        targetChainId
      }
      finalizeBlockEvent {
        blockWinner
        signature
        sender
        signerAddress
        epoch
        dynasty
        messageData
        transactionHash
      }
      validatorSelectionEvent {
        validatorId
        activeSet
        iteration
        transactionHash
        timestamp
        isValidatorSelected
      }
      stakedEvent {
        amount
        dynasty
        epoch
        transactionHash
        validator
        validatorId
      }

      validatorsChurnedOutEvent {
        churnedOutValidators
        dynasty
        epoch
        id
        timestamp
        transactionHash
      }
      withdrawEvent {
        validatorId
        validator
        transactionHash
        mode
        id
        epoch
        dynasty
      }
      parameterEvent {
        valueChangedTo
        timestamp
        parameterName
        id
        admin
      }
      attestBlameEvent {
        blameType
        culprits
        dynasty
        epoch
        id
        sender
        transactionHash
      }

      delegatedEvent {
        id
        delegator
        validator
        validatorId
        amount
        prevTotalStake
        newTotalStake
        totalSupply
        epoch
        dynasty
        transactionHash
        timestamp
      }

      validatorRewardChanged {
        id
        validator
        reason
        prevClaimableAmount
        newClaimableAmount
        dynasty
        epoch
        transactionHash
      }

      bridgeRecoveryEvent {
        id
        activeSetBeingJailedSlashed
        epoch
        dynasty
        transactionHash
      }

      blameConsensusEvent {
        id
        blameType
        culprits
        epoch
        dynasty
        transactionHash
      }

      chainStatusChangedEvent {
        id
        chainDomain
        isSupported
        epoch
        dynasty
        sender
        timestamp
        transactionHash
      }
      signerProofDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
        transactionHash
      }

      validatorJailedEvent {
        jailEndDynasty
        sender
        transactionHash
        validatorId
        epoch
        dynasty
        id
      }
      blockDisputeReachedEvent {
        activeSet
        dynasty
        epoch
        id
        reason
        sender
      }
      commissionUpdatedEvent {
        commission
        id
        validator
        validatorId
      }
      delegationAcceptanceChangedEvent {
        acceptDelegation
        id
        validator
        validatorId
      }
      escapeHatchEvent {
        amount
        dynasty
        epoch
        id
        sender
      }
      genesisResetTimestampSetEvent {
        genesisResetTimestamp
        id
        initModeTimePeriod
      }
      messageConsensusReachedEvent {
        requestId
        requestingChainDomain
        destinationChainDomain
        target
        dynasty
        epoch
        id
        message
      }
      signerConsensusReachedEvent {
        signerAddress
        id
        epoch
        dynasty
      }
    }
  }
`;

export const FETCH_ACTIVE_SET_VALIDATORS = gql`
  query($orderBy: String!, $orderDirection: String!, $isChurnedOut: Boolean!) {
    validators(
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      first: $first
      where: { isChurnedOut: $isChurnedOut }
    ) {
      stake
      validatorId
      lastAttestedEpoch
      lastAttestedDynasty
      lastFinalizedBlock
      lastEpochChurnedOut
      lastDynastyChurnedOut
      isChurnedOut
      address
      delegationAccepted
      dToken
    }
  }
`;
