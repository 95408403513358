import { useEffect } from "react";

// Chakra imports
import {
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import { useLazyQuery } from "@apollo/client";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import RequestRow from "components/Tables/RequestRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useState } from "react";
import { FETCH_REQUESTS } from "utils/queries";

function Requests() {
  const NUM_REQUESTS = 50;
  const [requests, setRequests] = useState([]);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [getAllRequests, { data: requestsData, loading }] = useLazyQuery(
    FETCH_REQUESTS,
    {
      variables: {
        orderBy: `indexedAt`,
        orderDirection: `desc`,
        first: NUM_REQUESTS,
        skip: skip,
      },
      pollInterval: 1000 * 100,
    }
  );

  useEffect(() => {
    fetchAllRequests();
    return () => {};
  }, []);

  useEffect(() => {
    if (requestsData?.requests) {
      if (requestsData.requests.length < NUM_REQUESTS) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setRequests((requests) => [...requests, ...requestsData.requests]);
    }
    console.log({ requestsData });
  }, [requestsData]);

  const fetchAllRequests = async () => {
    setIsLoading(true);
    await getAllRequests();
    setIsLoading(false);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Requests
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={() => setSkip(skip + NUM_REQUESTS)}
                isLoading={loading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Request ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Status
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  First Committed Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Finalized Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Number of Commits
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Indexed At
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && requests.length === 0 ? (
                <SkeletonTable rowCount={10} />
              ) : requests.length === 0 ? (
                <RequestRow row={requests} index={0} lastItem={true} />
              ) : (
                requests.length > 0 &&
                requests.map((row, index, arr) => {
                  return (
                    <RequestRow
                      row={row}
                      key={index}
                      lastItem={index === arr.length - 1 ? true : false}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Requests;
